/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react";
import mixpanel from "mixpanel-browser";
import Cookies from "js-cookie";
import { MixpanelContext } from "./src/tracking";
import { UserDataProvider } from "./src/userDataContext"

export const wrapRootElement = ({ element }) => {
    mixpanel.init(
        process.env.MIXPANEL_PROJECT_KEY,
        {
            debug: process.env.NODE_ENV === "development"
        }
    );

    return (
        <MixpanelContext.Provider value={mixpanel}>
            <UserDataProvider>
                {element}
            </UserDataProvider>
        </MixpanelContext.Provider>
    );
};


/* globals window */
export const onInitialClientRender = () => {
    mixpanel.init(
        process.env.MIXPANEL_PROJECT_KEY,
        {
            debug: process.env.NODE_ENV === "development"
        }
    );

    const sameSite = process.env.SECURE_COOKIE ? "none" : "lax";

    Cookies.defaults = {
        path: "/",
        domain: process.env.COOKIE_DOMAIN,
        expires: Infinity,
        secure: process.env.SECURE_COOKIE,
        sameSite
    };

    // Process UTM params and save it
    const utmParams = {};

    window.location.search.substr(1).split("&").forEach(param => {
        const kv = param.split("=").map(decodeURIComponent);

        const utmParam = kv[0].match(/^utm_(\w+)$/);
        if (utmParam) {
            [, utmParams[utmParam[1]]] = kv;
        }
    });

    if (Object.keys(utmParams).length) {
        Cookies.set("utmParams", JSON.stringify(utmParams));
    }
}
